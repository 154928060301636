import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { AuthContextProvider } from "./contexts/AuthContext";
import { KAKAO_JS_KEY } from "./utils/const";
import { HelmetProvider } from "react-helmet-async";
import { setScreenHeight } from "./utils/mobile";

function App() {
  useEffect(() => {
    const { Kakao } = window;
    if (Kakao && !Kakao.isInitialized()) Kakao.init(KAKAO_JS_KEY);
  }, []);

  useEffect(() => {
    setScreenHeight();

    window.addEventListener("resize", setScreenHeight);
    return () => window.removeEventListener("resize", setScreenHeight);
  }, []);

  return (
    <HelmetProvider>
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </HelmetProvider>
  );
}

export default App;
