/**
 * 프로젝트 페이지
 */

import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
import { PATHS } from "./paths";

const LoginPage = lazy(() => import("../pages/login"));
const BaekhoelevenLoginPage = lazy(() => import("../pages/login/baekhoeleven"));
const BloomAdminLoginPage = lazy(() => import("../pages/login/bloomAdmin"));
const Bloom = lazy(() => import("../pages/login/bloom"));
const LoginSuccess = lazy(() => import("../pages/login/success"));
const LoginCancel = lazy(() => import("../pages/login/cancel"));
const GoogleOauthPage = lazy(() => import("../pages/_o/google"));
const KakaoOauthPage = lazy(() => import("../pages/_o/kakao"));
const NaverOauthPage = lazy(() => import("../pages/_o/naver"));
const AppleOauthPage = lazy(() => import("../pages/_o/apple"));
const routes = [
  {
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: PATHS.login.index,
        element: <LoginPage />,
      },
      {
        path: PATHS.login.baekhoeleven,
        element: <BaekhoelevenLoginPage />,
      },
      {
        path: PATHS.login.bloom,
        element: <Bloom />,
      },
      {
        path: PATHS.login.bloomAdmin,
        element: <BloomAdminLoginPage />,
      },
      {
        path: PATHS.login.success,
        element: <LoginSuccess />,
      },
      {
        path: PATHS.login.cancel,
        element: <LoginCancel />,
      },
      {
        path: PATHS.oauth.google,
        element: <GoogleOauthPage />,
      },
      {
        path: PATHS.oauth.kakao,
        element: <KakaoOauthPage />,
      },
      {
        path: PATHS.oauth.naver,
        element: <NaverOauthPage />,
      },
      {
        path: PATHS.oauth.apple,
        element: <AppleOauthPage />,
      },
    ],
  },
];

export default routes;
