/**
 * 에러 관련 페이지
 */

import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
import { PATHS } from "./paths";

const Page403 = lazy(() => import("../pages/403"));
const Page404 = lazy(() => import("../pages/404"));
const Page500 = lazy(() => import("../pages/500"));

const errorRoutes = [
  {
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: PATHS.page403,
        element: <Page403 />,
      },
      {
        path: PATHS.page404,
        element: <Page404 />,
      },
      {
        path: PATHS.page500,
        element: <Page500 />,
      },
    ],
  },
];

export default errorRoutes;
