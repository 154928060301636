import i18n, { PostProcessorModule } from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import localeDefaultConfig from "./default-config.json";

import koKR from "./locales/ko-KR/translation.json";
import enUS from "./locales/en-US/translation.json";

const resources = {
  ko: { translation: koKR },
  en: { translation: enUS },
};

const exposeKeyOnTranslationProcessor: PostProcessorModule = {
  name: "exposeKeyOnTranslation",
  type: "postProcessor",
  process: function (value: string, key: string) {
    return value === "[작성 필요]" ? key : value;
  },
};

i18n
  .use(exposeKeyOnTranslationProcessor)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["querystring"],
    },
    resources,
    postProcess: ["exposeKeyOnTranslation"],
    fallbackLng: localeDefaultConfig["fallback-language"],
    debug: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler(key, defaultValue) {
      console.warn("Missing i18n key:", key, defaultValue);
    },
  });

export default i18n;
