export const PATHS = {
  root: "/",
  login: {
    index: "/login",
    baekhoeleven: "/login/baekhoeleven",
    bloom: "/login/bloom",
    bloomAdmin: "/login/bloom/admin",
    success: "/login/success",
    cancel: "/login/cancel",
  },
  oauth: {
    google: "/_o/google",
    kakao: "/_o/kakao",
    naver: "/_o/naver",
    apple: "/_o/apple",
  },
  page403: "/403",
  page404: "/404",
  page500: "/500",
};
