import { ReactNode, createContext, useContext } from "react";
import { AuthConextType, TAuthType } from "./types";
import {
  APPLE_AUTH_URL,
  APPLE_CLIENT_ID,
  FRONT_URL,
  GOOGLE_OIDC_AUTH_URL,
  GOOGLE_OIDC_CLIENT_ID,
  NAVER_AUTH_URL,
  NAVER_CLIENT_ID,
  RENEW_ACCESS_TOKEN_NAME,
  RENEW_REFRESH_TOKEN_NAME,
  PARTNER_ID_SESSION_KEY,
  PARTNER_TOKEN_NAME,
  BLOOM_ADMIN_REDIRECT_NAME,
} from "../../utils/const";
import { PATHS } from "../../routes/paths";
import qs from "qs";
import { axiosInstance } from "../../utils/axios";
import { setCookie } from "../../utils/cookie";
import { getUserIdAtAccessToken } from "../../utils/jwt";

const AuthContext = createContext<AuthConextType | undefined>(undefined);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const savePartner = (partnerId?: string | null) => {
    if (!partnerId) return false;
    sessionStorage.setItem(PARTNER_ID_SESSION_KEY, partnerId);
    return true;
  };

  const login = (authType: TAuthType) => {
    if (authType === "GOOGLE") {
      return googleLogin();
    } else if (authType === "KAKAO") {
      return kakaoLogin();
    } else if (authType === "NAVER") {
      return naverLogin();
    } else if (authType === "APPLE") {
      return appleLogin();
    }
  };

  const googleLogin = () => {
    const queryStr = qs.stringify({
      client_id: `${GOOGLE_OIDC_CLIENT_ID}`,
      redirect_uri: `${FRONT_URL}${PATHS.oauth.google}`,
      response_type: "code",
      scope: "openid profile email",
      prompt: "select_account",
    });
    const loginUrl = `${GOOGLE_OIDC_AUTH_URL}?${queryStr}`;
    window.location.href = loginUrl;
  };

  const kakaoLogin = () => {
    if (!window?.Kakao) {
      console.error("Can not find KAKAO provider");
    }
    window.Kakao.Auth.authorize({
      redirectUri: `${FRONT_URL}${PATHS.oauth.kakao}`,
    });
  };

  const naverLogin = () => {
    const naverReprompt = false;
    const queryStr = qs.stringify({
      client_id: `${NAVER_CLIENT_ID}`,
      redirect_uri: `${FRONT_URL}${PATHS.oauth.naver}`,
      response_type: "code",
      auth_type: `${naverReprompt ? "reprompt" : "reauthenticate"}`,
    });
    const loginUrl = `${NAVER_AUTH_URL}?${queryStr}`;

    window.location.href = loginUrl;
  };

  const appleLogin = () => {
    const config = {
      client_id: APPLE_CLIENT_ID,
      redirect_uri: `${FRONT_URL}${PATHS.oauth.apple}`,
      response_type: "code id_token",
    };
    const queryString = Object.entries(config)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const loginUrl = `${APPLE_AUTH_URL}?${queryString}`;
    window.location.href = loginUrl;
  };

  const issueTokens = async (authType: TAuthType, code: string) => {
    const partnerId = sessionStorage.getItem(PARTNER_ID_SESSION_KEY) || "";

    if (!partnerId) {
      return console.error("partnerId를 찾을 수 없습니다.");
    }

    try {
      const {
        data: {
          accessToken,
          refreshToken,
          renewAccessToken,
          renewRefreshToken,
        },
      } = await axiosInstance.post("/v1/user-actions/sign/oauth", {
        authType,
        code,
        redirectUri: `${FRONT_URL}${
          (PATHS.oauth as any)[authType.toLowerCase()]
        }`,
        joinedFrom: "PARTNER",
        partnerId,
      });

      if (
        !accessToken ||
        !refreshToken ||
        !renewAccessToken ||
        !renewRefreshToken
      ) {
        throw new Error("토큰을 발급 받지 못했습니다.");
      }

      const { data: partner_data } = await axiosInstance.get(
        `/v1/api/bypass/${partnerId}`,
        {
          headers: {
            Authorization: `Bearer ${renewAccessToken}`,
          },
        }
      );

      if (partner_data.authMethod === "JWT") {
        saveTokenAndRedirect({
          accessToken,
          refreshToken,
          renewAccessToken,
          renewRefreshToken,
          redirectUrl: partner_data.redirectUrl,
        });
      } else if (partner_data.authMethod === "USERID") {
        parseUserIdAndRediect({
          accessToken: renewAccessToken,
          redirectUrl: partner_data.redirectUrl,
        });
      } else if (partner_data.authMethod === "PARTNER_JWT") {
        const bloomAdminRedirect = sessionStorage.getItem(
          BLOOM_ADMIN_REDIRECT_NAME
        );
        saveTokenAndRedirect({
          accessToken,
          refreshToken,
          renewAccessToken,
          renewRefreshToken,
          partnerJwt: partner_data.partnerJwt,
          redirectUrl: !bloomAdminRedirect
            ? PATHS.login.success
            : `${bloomAdminRedirect}?partnerJwt=${partner_data.partnerJwt}`,
        });
        sessionStorage.removeItem(BLOOM_ADMIN_REDIRECT_NAME);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const saveTokenAndRedirect = ({
    accessToken,
    refreshToken,
    renewAccessToken,
    renewRefreshToken,
    partnerJwt,
    redirectUrl,
  }: {
    accessToken: string;
    refreshToken: string;
    renewAccessToken: string;
    renewRefreshToken: string;
    partnerJwt?: string;
    redirectUrl: string;
  }) => {
    const isProduction = process.env.NODE_ENV === "production";
    const isTestMode = redirectUrl.includes("http://localhost");
    const domain = isProduction && !isTestMode ? ".cccv.to" : "localhost";

    setCookie(RENEW_ACCESS_TOKEN_NAME, renewAccessToken, {
      path: "/",
      domain,
    });
    setCookie(RENEW_REFRESH_TOKEN_NAME, renewRefreshToken, {
      path: "/",
      domain,
    });
    if (partnerJwt) {
      setCookie(PARTNER_TOKEN_NAME, partnerJwt, {
        path: "/",
        domain,
      });
    }
    window.location.replace(redirectUrl);
  };

  const parseUserIdAndRediect = ({
    accessToken,
    redirectUrl,
  }: {
    accessToken: string;
    redirectUrl: string;
  }) => {
    const userId = getUserIdAtAccessToken(accessToken);
    window.location.replace(`${redirectUrl}?userId=${userId}`);
  };

  return (
    <AuthContext.Provider value={{ login, issueTokens, savePartner }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthContextProvier");
  }
  return context;
};
