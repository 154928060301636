import { jwtDecode } from "jwt-decode";

export const getUserIdAtAccessToken = (token?: string) => {
  try {
    if (!token) return;
    const { userId } = jwtDecode<any>(token);

    return userId;
  } catch (e) {
    return "";
  }
};

export const getRoledAtAccessToken = (token?: string): string[] => {
  try {
    if (!token) return [];
    const { siteRoles } = jwtDecode<any>(token);

    return siteRoles;
  } catch (e) {
    return [];
  }
};
