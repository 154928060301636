import { Navigate, createBrowserRouter } from "react-router-dom";
import { PATHS } from "./paths";
import routes from "./routes";
import errorRoutes from "./error";

export const router = createBrowserRouter([
  {
    path: PATHS.root,
    element: <Navigate to={PATHS.login.index} replace />,
  },
  ...routes,
  ...errorRoutes,
  { path: "*", element: <Navigate to="/404" replace /> },
]);
