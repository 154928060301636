// 프로젝트의 URL
export const FRONT_URL =
  process.env.REACT_APP_FRONT_URL || "http://localhost:3000";

// API Base URL
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://prod-dev.cccv.to";

// GOOGLE
export const GOOGLE_OIDC_AUTH_URL =
  process.env.REACT_APP_GOOGLE_OIDC_AUTH_URL ||
  "https://accounts.google.com/o/oauth2/v2/auth";
export const GOOGLE_OIDC_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_OIDC_CLIENT_ID ||
  "1041725413948-5jernrpkcp85fhnku2rtdc1gce5j3k0b.apps.googleusercontent.com";

// KAKAO
export const KAKAO_JS_KEY =
  process.env.REACT_APP_KAKAO_JS_KEY || "eef6380e7233d1bc5b9159ffe7715ff8";

//NAVER
export const NAVER_AUTH_URL =
  process.env.REACT_APP_NAVER_AUTH_URL ||
  "https://nid.naver.com/oauth2.0/authorize";
export const NAVER_CLIENT_ID =
  process.env.REACT_APP_NAVER_CLIENT_ID || "p6qpyWAZmb3w98NJNQb9";

//APPLE
export const APPLE_AUTH_URL =
  process.env.REACT_APP_APPLE_AUTH_URL ||
  "https://appleid.apple.com/auth/authorize";
export const APPLE_CLIENT_ID =
  process.env.REACT_APP_APPLE_CLIENT_ID || "to.cccv.nft.dev-web";

// COOKIE 이름
export const ACCESS_TOKEN_NAME = "accessToken";
export const REFRESH_TOKEN_NAME = "refreshToken";
export const RENEW_ACCESS_TOKEN_NAME = "renewAccessToken";
export const RENEW_REFRESH_TOKEN_NAME = "renewRefreshToken";
export const PARTNER_TOKEN_NAME = "partnerJwt";
export const BLOOM_ADMIN_REDIRECT_NAME = "bloom_admin_redirect";

// 서비스이용약관
export const POLICY_URL =
  "https://www.notion.so/blockoxyz/50636c51e800496fa4901b8dab0d08fb?pvs=4";

// 개인정보취급방침
export const PRIVACY_URL =
  "https://www.notion.so/blockoxyz/d940f81048bf4b09be8839cf857800c4?pvs=4";

export const PARTNER_ID_SESSION_KEY = "PARTNER_ID";
export const PARTNER_DATA_SESSION_KEY = "PARTNER_SESSION";
